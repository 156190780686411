export const menuList = [
  {
    section: "Home",
    label: "home",
  },
  {
    section: "Services",
    label: "services",
  },
  // {
  //   section: "works-section",
  //   label: "works",
  // },
  {
    section: "Resume",
    label: "resume",
  },
  {
    section: "Contact",
    label: "contact",
  },
];
