import { stats } from "./constants";

import styles from "./index.module.scss";

const HomeStats = () => {
  return (
    <section className={styles.section}>
      {stats.map((elem) => (
        <div key={elem.text}>
          <span className={styles.amount}>{elem.amount}</span>
          {elem.withPlus && <span className={styles.plus}>+</span>}
          <span className={styles.text}>{elem.text}</span>
        </div>
      ))}
    </section>
  );
};

export default HomeStats;
