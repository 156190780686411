export const stats = [
  {
    text: "Years of Experience",
    amount: 3,
    withPlus: true,
  },
  {
    text: "Completed Projects",
    amount: 80,
    withPlus: true,
  },
  {
    text: "Happy Customers",
    amount: 150,
    withPlus: true,
  },
  {
    text: "Companies",
    amount: 5,
    withPlus: false,
  },
];
