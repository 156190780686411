import HeaderBackground from "@Assets/images/Header-background.jpg";
import PersonalPhoto from "@Assets/images/personal-photo.png";

import styles from "./index.module.scss";
import NavigationButton from "@Components/Shared/NavigationButton";

const HomeHeader = () => {
  return (
    <section className={styles.section} id="Home">
      <div className={styles.background}>
        <div>
          <img src={HeaderBackground} alt="Header" />
          <div></div>
        </div>
      </div>
      <div className={styles.container}>
        <img
          className={styles.backgroundImage}
          src={HeaderBackground}
          alt="Header"
        />
        <img
          className={styles.personalPhoto}
          src={PersonalPhoto}
          alt="Personal"
        />
        <div className={styles.backgroundCover}></div>
        <h1>Discover my Amazing World!</h1>
        <p>
          &lt;<span>code</span>&gt; I build web applications &lt;/
          <span>code</span>&gt;
        </p>
        <NavigationButton text="CONTACT ME" link="#Contact" />
      </div>
    </section>
  );
};

export default HomeHeader;
