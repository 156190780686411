export const userInfo = {
  Residence: "Armenia",
  City: "Yerevan",
  Age: new Date().getFullYear() - new Date("01/04/2002").getFullYear(),
};

export const languageInfo = [
  {
    name: "Armenian",
    value: 100,
  },
  {
    name: "English",
    value: 85,
  },
  {
    name: "Russian",
    value: 60,
  },
];

export const progLanguages = [
  {
    name: "Typescript",
    value: 95,
  },
  {
    name: "Golang",
    value: 75,
  },

  {
    name: "SQL",
    value: 80,
  },
  {
    name: "Kotlin",
    value: 40,
  },
  {
    name: "C++",
    value: 40,
  },
];

export const skills = [
  "Node.js, React.js, Next.js",
  "SASS, Material UI, Antd design",
  "Webpack, Parcel",
  "Express.js, Nest.js, Koa.js",
  "PostgreSQL, MySQL, MongoDB",
  "Redis, MSSQL",
  "DynamoDB, BigQuery",
  "AWS, GCP",
];
