import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useMediaQuery } from "@mui/material";
import RightSidebarHeader from "./Header";
import RightSidebarMenu from "./Menu";
import { useCallback } from "react";

import styles from "./index.module.scss";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightSidebar: React.FC<IProps> = ({ open, setOpen }) => {
  const isMobile = useMediaQuery("(max-width: 1032px)");

  const handleClickAway = useCallback(
    (e: any) => {
      if (
        open &&
        !(
          e.target.id === "right-sidebar-button-icon" ||
          e.target.parentNode.id === "right-sidebar-button-icon"
        )
      ) {
        setOpen(false);
      }
    },
    [setOpen, open]
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles.container}>
        <nav
          className={`${styles.sidebar} ${
            open && isMobile ? styles.containerMobile : null
          }`}
          style={{
            width: open ? "230px" : "80px",
          }}
        >
          <RightSidebarHeader open={open} setOpen={setOpen} />
          <RightSidebarMenu open={open} />
        </nav>
        <div
          className={styles.block}
          style={{
            width: open ? "230px" : "80px",
          }}
        ></div>
      </div>
    </ClickAwayListener>
  );
};

export default RightSidebar;
