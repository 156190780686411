export const contactInfo = [
  {
    Country: "Armenia",
    City: "Yerevan",
    Street: "Nikoghayos Tigranyan",
  },
  {
    Email: "sisnikoyan@gmail.com",
    Telegram: "@niksis02",
    Phone: "+374 (091) 500 677",
  },
  {
    hello: "world",
    hello1: "world1",
    hello2: "world2",
  },
];
