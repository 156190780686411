import styles from "./index.module.scss";

interface IProps {
  date: string;
  title: string;
  role: string;
  description: string;
  titleLink?: string;
}

const ExperienceTemplate: React.FC<IProps> = ({
  date,
  title,
  role,
  description,
  titleLink,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        {titleLink ? (
          <a
            href={titleLink}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            {title}
          </a>
        ) : (
          <span className={styles.link}>{title}</span>
        )}
        <div>
          <span>{date}</span>
        </div>
      </div>
      <p>{role}</p>
      <span>{description}</span>
      <div className={styles.dot}></div>
    </div>
  );
};

export default ExperienceTemplate;
