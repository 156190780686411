import DoneIcon from "@mui/icons-material/Done";

import styles from "./index.module.scss";

interface IProps {
  values: string[];
}

const SkillsList: React.FC<IProps> = ({ values }) => {
  return (
    <ul className={styles.container}>
      {values.map((elem) => (
        <li key={elem}>
          <DoneIcon />
          <span>{elem}</span>
        </li>
      ))}
    </ul>
  );
};

export default SkillsList;
