import { LinearProgress } from "@mui/material";

import styles from "./index.module.scss";

interface IProps {
  values: {
    name: string;
    value: number;
  }[];
}

const LinearProgresses: React.FC<IProps> = ({ values }) => {
  return (
    <ul className={styles.container}>
      {values.map((elem) => (
        <li key={elem.name}>
          <div>
            <h6>{elem.name}</h6>
            <span>{elem.value} %</span>
          </div>
          <LinearProgress
            className={styles.linearProgress}
            variant="determinate"
            value={elem.value}
            aria-label={elem.name}
          />
        </li>
      ))}
    </ul>
  );
};

export default LinearProgresses;
