import { ChangeEvent, FormEvent, useState, FocusEvent } from "react";
import PersonIcon from "@mui/icons-material/Person";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MessageIcon from "@mui/icons-material/MailOutline";
import emailjs from "emailjs-com";
import { envConfig } from "Config/env";

import styles from "./index.module.scss";

const ContactForm = () => {
  const [emailInfo, setEmailInfo] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [emailFocus, setEmailFocus] = useState({
    name: false,
    email: false,
    message: false,
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEmailInfo((info) => ({ ...info, [name]: value }));
  };

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.type === "focus")
      setEmailFocus((focus) => ({ ...focus, [e.target.name]: true }));
    if (e.type === "blur")
      setEmailFocus((focus) => ({ ...focus, [e.target.name]: false }));
  };

  const handleSendEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!emailInfo.email || !emailInfo.name || !emailInfo.message) {
      return;
    }
    try {
      await emailjs.send(
        envConfig.EMAILJS_SERVICE_ID,
        envConfig.EMAILJS_TEMPLATE_ID,
        emailInfo,
        envConfig.EMAILJS_PUBLIC_KEY
      );
      setEmailInfo({ name: "", email: "", message: "" });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className={styles.section} id="Contact">
      <h4>Get in touch</h4>
      <form onSubmit={handleSendEmail}>
        <label>
          <div className={emailFocus.name ? styles.active : undefined}>
            <PersonIcon />
          </div>
          <input
            onChange={handleChange}
            value={emailInfo.name}
            name="name"
            type="text"
            placeholder="Name"
            onFocus={handleFocus}
            onBlur={handleFocus}
          />
        </label>
        <label>
          <div className={emailFocus.email ? styles.active : undefined}>
            <AlternateEmailIcon />
          </div>
          <input
            onChange={handleChange}
            value={emailInfo.email}
            name="email"
            type="email"
            placeholder="Email"
            onFocus={handleFocus}
            onBlur={handleFocus}
          />
        </label>
        <label className={styles.textareaContainer}>
          <div className={emailFocus.message ? styles.active : undefined}>
            <MessageIcon />
          </div>
          <textarea
            value={emailInfo.message}
            onChange={handleChange}
            name="message"
            placeholder="Message"
            onFocus={handleFocus}
            onBlur={handleFocus}
            className={emailFocus.message ? styles.textareaActive : undefined}
          ></textarea>
        </label>
        <button value={emailInfo.message} type="submit">
          SEND MESSAGE
        </button>
      </form>
    </section>
  );
};

export default ContactForm;
