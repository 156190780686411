import ServiceTemplate from "@Components/Shared/ServiceTemplate";
import { services } from "./constants";

import styles from "./index.module.scss";

const Services = () => {
  return (
    <section className={styles.section} id="Services">
      <h2>My Services</h2>
      <div>
        {services.map((elem) => (
          <ServiceTemplate
            key={elem.title}
            title={elem.title}
            description={elem.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Services;
