export const services = [
  {
    title: "Front-end development",
    description:
      "Build front-end web application of any type(mainly react) from scratch and deliver to the client",
  },
  {
    title: "Front-end development1",
    description:
      "Build front-end web application of any type(mainly react) from scratch and deliver to the client",
  },
  {
    title: "Front-end development2",
    description:
      "Build front-end web application of any type(mainly react) from scratch and deliver to the client",
  },
  {
    title: "Front-end development3",
    description:
      "Build front-end web application of any type(mainly react) from scratch and deliver to the client",
  },
  {
    title: "Front-end development4",
    description:
      "Build front-end web application of any type(mainly react) from scratch and deliver to the client",
  },
  {
    title: "Front-end development5",
    description:
      "Build front-end web application of any type(mainly react) from scratch and deliver to the client",
  },
];
