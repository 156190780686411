import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import styles from "./index.module.scss";

interface IProps {
  setRightSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLeftSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar: React.FC<IProps> = ({
  setLeftSideBarOpen,
  setRightSidebarOpen,
}) => {
  const isMobile = useMediaQuery("(max-width: 1032px)");

  return (
    <>
      <div className={styles.upperBlock}></div>
      <div className={styles.navbar}>
        {isMobile && (
          <>
            <IconButton onClick={() => setLeftSideBarOpen(true)}>
              <MoreVertIcon id="left-sidebar-button-icon" />
            </IconButton>
            <IconButton onClick={() => setRightSidebarOpen(true)}>
              <MenuIcon id="right-sidebar-button-icon" />
            </IconButton>
          </>
        )}
      </div>
      <div className={styles.lowerBlock}></div>
    </>
  );
};

export default Navbar;
