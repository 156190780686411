import { Box, CircularProgress, Typography } from "@mui/material";

import styles from "./index.module.scss";

interface IProps {
  values: {
    name: string;
    value: number;
  }[];
}

const CircularProgresses: React.FC<IProps> = ({ values }) => {
  return (
    <div className={styles.container}>
      {values.map((elem) => (
        <Box
          key={elem.name}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
            }}
          >
            <CircularProgress
              size={53}
              className={styles.circularProgress}
              variant="determinate"
              value={elem.value}
              aria-label={elem.name}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="white"
              >{`${Math.round(elem.value)}%`}</Typography>
            </Box>
          </Box>
          <Typography sx={{ color: "white", fontSize: 13 }}>
            {elem.name}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

export default CircularProgresses;
