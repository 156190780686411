import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import styles from "./index.module.scss";

interface IProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftSidebarHeader: React.FC<IProps> = ({ setOpen }) => {
  const isMobile = useMediaQuery("(max-width: 1032px)");

  return (
    <div className={styles.header}>
      <img
        src="https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg"
        alt="Profile pic"
      />
      <h5>Sis Nikoyan</h5>
      <span>Software developer</span>
      {isMobile && (
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )}
    </div>
  );
};

export default LeftSidebarHeader;
