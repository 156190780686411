import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import styles from "./index.module.scss";

interface IProps {
  title: string;
  description: string;
}

const ServiceTemplate: React.FC<IProps> = ({ title, description }) => {
  return (
    <div className={styles.container}>
      <h3>{title}</h3>
      <p>{description}</p>
      <a href="#contact-section">
        <span>ORDER NOW</span>
        <ArrowForwardIosIcon />
      </a>
    </div>
  );
};

export default ServiceTemplate;
