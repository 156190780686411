import LinkedinLogo from "@Assets/icons/linkedin.svg";
import GithubLogo from "@Assets/icons/github.svg";
import CodeWarsLogo from "@Assets/icons/codewars.svg";
import FacebookLogo from "@Assets/icons/facebook.svg";
import InstagramLogo from "@Assets/icons/instagram.svg";

interface IIcons {
  name: string;
  icon: string;
  link: string;
}

export const socialIcons: IIcons[] = [
  {
    name: "Linkedin",
    icon: LinkedinLogo,
    link: "https://www.linkedin.com/in/sis-nikoyan-93b1b2229/",
  },
  {
    name: "Github",
    icon: GithubLogo,
    link: "https://github.com/niksis02",
  },
  {
    name: "Codewars",
    icon: CodeWarsLogo,
    link: "https://www.codewars.com/users/xxNIKOYANxx",
  },
  {
    name: "Facebook",
    icon: FacebookLogo,
    link: "https://www.facebook.com/niksis02",
  },
  {
    name: "Instagram",
    icon: InstagramLogo,
    link: "https://www.instagram.com/niksis02/",
  },
];
