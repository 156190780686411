export const workExperience = [
  {
    date: "Oct 2022 - Present",
    title: "Solicy",
    role: "Software Developer",
    description:
      "As a software developer I work on various types of projects, including web2, web3, mobile and desktop",
    titleLink: "https://solicy.net/",
  },
  {
    date: "Feb 2022 - Oct 2022",
    title: "Studio-One",
    role: "Back-end Developer",
    description: `As a back-end developer I have worked on a web back-end application
      which was a large scale Microservice application. Except for this I worked
      on an application, which was dealing with remote devices using some
      messaging protocols.`,
    titleLink: "https://www.studio-one.am/",
  },
  {
    date: "Apr 2021 - Feb 2022",
    title: "Evistep Enterprise",
    role: "Back-end Developer",
    description:
      "As a back-end developer I built a serverless web back-end application with Node.js and AWS from scratch.",
    titleLink: "https://evistep.com/",
  },
  {
    date: "Jun 2020 - Apr 2021",
    title: "OnTime",
    role: "Front-end Developer",
    description:
      "As a front-end React JS developer I have worked on many web front-end applications building websites both from scratch and production-ready ones.",
  },
  {
    date: "Jan 2020 - Jun 2020",
    title: "Startup Benefits",
    role: "Back-end Developer",
    description:
      "As a back-end developer, I built the company website back-end application from scratch with Node.js.",
    titleLink: "https://www.startupbenefits.eu/",
  },
];

export const education = [
  {
    date: "Sep 2020 - May 2024",
    title: "Yerevan State University",
    role: "Student",
    description:
      "Bachelor's degree in Informatics and applied mathematics, Yerevan.",
    titleLink: "https://solicy.net/",
  },
];
