import ExperienceTemplate from "@Components/Shared/ExperienceTemplate";
import { education, workExperience } from "./constants";

import styles from "./index.module.scss";

const Experience = () => {
  return (
    <section className={styles.section} id="Resume">
      <div>
        <h4>Work History</h4>
        <div className={styles.container}>
          {workExperience.map((elem) => (
            <ExperienceTemplate
              key={elem.title}
              title={elem.title}
              titleLink={elem.titleLink}
              description={elem.description}
              role={elem.role}
              date={elem.date}
            />
          ))}
          <div className={styles.pole}></div>
        </div>
      </div>
      <div>
        <h4>Education</h4>
        <div className={styles.container}>
          {education.map((elem) => (
            <ExperienceTemplate
              key={elem.title}
              title={elem.title}
              titleLink={elem.titleLink}
              description={elem.description}
              role={elem.role}
              date={elem.date}
            />
          ))}
          <div className={styles.pole}></div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
