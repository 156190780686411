import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CV from "@Assets/other/CV_Sis_Nikoyan.pdf";

import styles from "./index.module.scss";

const DownloadCV = () => {
  return (
    <a className={styles.container} href={CV} download>
      <span>DOWNLOAD CV</span>
      <FileDownloadIcon />
    </a>
  );
};

export default DownloadCV;
