import styles from "./index.module.scss";

interface IProps {
  values: { [key: string]: any };
}

const ContactInfoTemplate: React.FC<IProps> = ({ values }) => {
  return (
    <div className={styles.container}>
      <ul>
        {Object.keys(values).map((elem) => (
          <li key={elem}>
            <strong>{elem}:</strong> <span>{values[elem]}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContactInfoTemplate;
