import HomeHeader from "@Components/Featured/HomeHeader";
import HomeStats from "@Components/Featured/HomeStats";
import Services from "@Components/Featured/Services";
import Experience from "@Components/Featured/Experience";
import ContactInfo from "@Components/Featured/ContactInfo";
import ContactForm from "@Components/Featured/Contact";

import styles from "./index.module.scss";

const Home = () => {
  return (
    <div className={styles.home}>
      <HomeHeader />
      <div className={styles.content}>
        <HomeStats />
        <Services />
        <Experience />
        <ContactInfo />
        <ContactForm />
      </div>
    </div>
  );
};

export default Home;
