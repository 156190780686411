import styles from "./index.module.scss";

interface IProps {
  info: Record<string, string | number>;
}

const KeyValueText: React.FC<IProps> = ({ info }) => {
  return (
    <ul className={styles.container}>
      {Object.keys(info).map((elem) => (
        <li key={elem}>
          <h6>{elem}:</h6>
          <span>{info[elem]}</span>
        </li>
      ))}
    </ul>
  );
};

export default KeyValueText;
