import ContactInfoTemplate from "@Components/Shared/ContactInfoTemplate";

import styles from "./index.module.scss";
import { contactInfo } from "./constants";

const ContactInfo = () => {
  return (
    <section className={styles.section}>
      <h4>Contact information</h4>
      <div>
        {contactInfo.map((elem, i) => (
          <ContactInfoTemplate key={i} values={elem} />
        ))}
      </div>
    </section>
  );
};

export default ContactInfo;
