import { menuList } from "./constants";

import styles from "./index.module.scss";

interface IProps {
  open: boolean;
}

const RightSidebarMenu: React.FC<IProps> = ({ open }) => {
  return (
    <ul className={styles.list}>
      {menuList.map((elem, i) => (
        <li
          key={elem.label}
          style={{
            opacity: open ? 1 : 0,
            transitionDelay: `${(i + 1) * 0.05}s`,
            transform: open ? "translateX(0)" : "translateX(60px)",
          }}
        >
          <a href={`#${elem.section}`}>{elem.label}</a>
        </li>
      ))}
    </ul>
  );
};

export default RightSidebarMenu;
