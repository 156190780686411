import styles from "./index.module.scss";

interface IProps {
  link: string;
  text: string;
  className?: string;
  handleClick?: () => void;
}

const NavigationButton: React.FC<IProps> = ({
  link,
  text,
  className,
  handleClick,
}) => {
  return (
    <a href={link} className={styles.button}>
      <button onClick={handleClick} className={className}>
        {text}
      </button>
    </a>
  );
};

export default NavigationButton;
