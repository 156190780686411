import { useCallback } from "react";
import CircularProgresses from "@Components/Shared/CircularProgresses";
import KeyValueText from "@Components/Shared/KeyValueText";
import LeftSidebarFooter from "./Footer";
import LeftSidebarHeader from "./Header";
import LinearProgresses from "@Components/Shared/LinearProgresses";
import SkillsList from "@Components/Shared/SkillsList";
import DownloadCV from "@Components/Shared/DownloadCV";
import { languageInfo, userInfo, skills, progLanguages } from "./constants";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useMediaQuery } from "@mui/material";

import styles from "./index.module.scss";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftSidebar: React.FC<IProps> = ({ open, setOpen }) => {
  const isMobile = useMediaQuery("(max-width: 1032px)");

  const handleClickAway = useCallback(
    (e: any) => {
      if (
        isMobile &&
        open &&
        !(
          e.target.id === "left-sidebar-button-icon" ||
          e.target.parentNode.id === "left-sidebar-button-icon"
        )
      )
        setOpen(false);
    },
    [isMobile, setOpen, open]
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={`${styles.container} ${
          isMobile && open ? styles.mobileOpen : null
        }`}
      >
        <div className={styles.leftSidebar}>
          <LeftSidebarHeader setOpen={setOpen} />
          <KeyValueText info={userInfo} />
          <hr />
          <CircularProgresses values={languageInfo} />
          <hr />
          <LinearProgresses values={progLanguages} />
          <hr />
          <SkillsList values={skills} />
          <hr />
          <DownloadCV />
          <LeftSidebarFooter />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default LeftSidebar;
