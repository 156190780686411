import { socialIcons } from "./constants";

import styles from "./index.module.scss";

const LeftSidebarFooter = () => {
  return (
    <div className={styles.footer}>
      {socialIcons.map((elem) => (
        <a key={elem.name} href={elem.link} target="_blank" rel="noreferrer">
          <img src={elem.icon} alt={elem.name} />
        </a>
      ))}
    </div>
  );
};

export default LeftSidebarFooter;
