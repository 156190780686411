import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

import styles from "./index.module.scss";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightSidebarHeader: React.FC<IProps> = ({ open, setOpen }) => {
  return (
    <div className={styles.header}>
      {open ? (
        <IconButton aria-label="close" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      ) : (
        <IconButton aria-label="burger menu" onClick={() => setOpen(true)}>
          <MenuIcon />
        </IconButton>
      )}
    </div>
  );
};

export default RightSidebarHeader;
