import { useState } from "react";
import RightSidebar from "@Components/Featured/RightSidebar";
import LeftSidebar from "./Components/Featured/LeftSidebar";
import Navbar from "./Components/Featured/Navbar";
import Home from "./Pages/Home";

function App() {
  const [rightSidebarOpen, setRightSidebarOpen] = useState<boolean>(false);
  const [leftSideBarOpen, setLeftSideBarOpen] = useState<boolean>(false);

  return (
    <div className="App">
      <Navbar
        setLeftSideBarOpen={setLeftSideBarOpen}
        setRightSidebarOpen={setRightSidebarOpen}
      />
      <LeftSidebar setOpen={setLeftSideBarOpen} open={leftSideBarOpen} />
      <Home />
      <RightSidebar open={rightSidebarOpen} setOpen={setRightSidebarOpen} />
    </div>
  );
}

export default App;
